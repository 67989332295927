.happer-widget--style-2 {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  background-color: $white;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
  padding: 13px 18px;
  border-radius: 4px;
  color: #111111;

  .happer-stars {
    .svg-icon.svg-icon-initial svg {
      height: 16px;
      width: 16px;
    }
  }

  .happer-symbol {
    width: 44px;
    height: 44px;
    border-radius: 2px;

    &.symbol .symbol-label {
      border-radius: 2px;
    }
  }

  .happer-widget__product {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 21px;
  }

  .happer-widget__product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 40px;
  }

  .happer-widget__product-link {
    &:hover {
      opacity: 0.8;
    }
  }

  .happer-widget__score {
    font-size: 16px;
    font-weight: bolder;
    color: #333;
    margin-right: 0;
    position: relative;
    line-height: 1;
    min-height: 98px;

    .svg-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    .svg-icon.svg-icon-initial svg {
      path:nth-child(1) {
        fill: var(--hp-main-color);
      }

      path:nth-child(2) {
        fill: var(--hp-main-color);
        opacity: 0.6;
      }
    }

    .happer-widget__highline {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 34px;
      height: 34px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .count {
    margin-top: 7px;
    margin-bottom: 16px;

    a {
      color: #209616;
    }
  }

  .slider-content {
    font-weight: 500;
    font-style: italic;
  }

  .happer-widget__rating-list {
    list-style: none;
    padding: 0 32px;

    .number {
      margin-right: 4px;
      width: 7px;
    }

    .svg-icon.svg-icon-initial svg {
      height: 10px;
      width: 10px;
      path {
        fill: #111111;
      }
    }

    li {
      display: flex;
      align-items: center;
    }

    .number-star {
      font-size: 14px;
      text-align: left;
      width: 30px;
      display: flex;
      align-items: center;
    }

    .timeline-star {
      background-color: #eee;
      height: 4px;
      position: relative;
      width: calc(100% - 60px);
      border-radius: 8px;
      overflow: hidden;
    }

    .number-percent {
      font-size: 14px;
      line-height: 15px;
      padding-left: 10px;
      text-align: left;
      width: 35px;
    }

    .timing {
      margin-bottom: 0;
      background-color: var(--hp-main-color);
      left: 0;
      height: 4px;
      position: absolute;
      top: 0;
    }
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--hp-main-color);
  }
}