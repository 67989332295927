.template-10 {
  max-width: 567px;
  margin-left: auto;
  margin-right: auto;
  /* frame-shadow */
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .happer-widget-inner {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 80px 10px;
    display: block;
    position: relative;

    @include media-breakpoint-down(450, (450:450px)) {
      padding: 10px 5px 10px;
    }
  }

  .happer-symbol {
    width: 44px;
    height: 44px;
    border-radius: 2px;
    margin-right: 13px;

    &.symbol .symbol-label {
      border-radius: 2px;
    }
  }

  .buy-now {
    background: #209616;
    color: #fff;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 14px;
    min-width: 135px;
    display: inline-block;

    .svg-icon {
      margin-right: 9px;
    }

    @include media-breakpoint-down(500, (500:500px)) {
      min-width: initial;
      padding: 5px 10px;
      .svg-icon {
        margin-right: 0;
      }
      .text {
        //display: none;
      }
    }
  }

  .count {
    color: $text-muted;

    a {
      color: $happer-color-2;
    }
  }

  .product-name {
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    a {
      color: #111111;

      &:hover {
        color: $happer-color-2
      }
    }
  }

  .svg-icon.svg-icon-initial svg {
    width: 25px;
    height: 25px;
  }

  .happer-widget-content {
    padding: 20px 28px;
    position: relative;
  }

  .path-arrow {
    width: 90px;
    height: 50px;
    position: relative;
    top: -12px;
    left: 20%;
    display: block;

    .polygon {
      width: 100%;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(0 0, 100% 0, 33.33% 100%, 0% 100%);
      border-bottom-left-radius: 10px;
    }

    &:after {
      content: "";
      width: 34px;
      height: 30px;
      border-radius: 50%;
      background-color: #fff;
      display: inline-block;
      position: absolute;
      bottom: -5px;
      left: 0px;
    }
  }

  .avatars {
    width: 80px;
    height: 80px;
    border: solid 5px #fff;
    border-radius: 50%;
    position: relative;
    margin-top: -48px;
    margin-left: -25px;
  }

  .happer-widget-review {
    background-color: #fff;
    padding: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @include media-breakpoint-down(450, (450:450px)) {
      padding: 25px;
      justify-content: start;
      align-items: start;
      flex-direction: column;

      .happer-widget-review-content {
        margin-bottom: 10px;
      }
    }
  }

  .happer-widget-review-content {
    flex: 1;
  }

  .user-name {
    display: inline-block;
    min-width: 250px;
    color: #000;
    background-color: #fff;
    border-radius: 99px;
    padding: 10px;
  }

  .share-facebook {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}