.happer-template-11 {
  background-color: transparent;
  #happer_content_container {
    padding: 0;
  }
}

.happer-button-popup {
  height: 40px;
  padding: 9px 15px;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $happer-color-2;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}

.happer-form {
  border-radius: 18px;
  height: calc(100% - 18px);
  max-height: 540px;
  top: 80%;
  width: 316px;
  overflow: hidden;

  #happer_content_container {
    padding: 0;
  }
}

.happer-review-form-wrapper {
  position: relative;
}

.happer-review-title {
  display: flex;
  justify-content: space-between;
  top: 0;
  position: fixed;
  height: 50px;
  width: 100%;
  padding: 5px 15px;
  align-items: center;
  background-color: #fff;
  z-index: 1;

  .close {
    position: relative;
    margin-right: -15px;
  }
}

.happer-review-form {
  padding: 50px 15px 15px;
  height: 100%;
  max-height: 380px;
  overflow-y: scroll;
}

.fv-plugins-message-container {
  color: red;
}