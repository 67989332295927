.template-6 {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  /* frame-shadow */

  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .happer-symbol {
    width: 44px;
    height: 44px;
    border-radius: 2px;
    margin-right: 13px;

    &.symbol .symbol-label {
      border-radius: 2px;
    }
  }

  .buy-now {
    background: #209616;
    color: #fff;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 14px;

    .svg-icon {
      margin-right: 9px;
    }

    @include media-breakpoint-down(500, (500:500px)) {
      padding: 6px 10px;
      .svg-icon {
        margin-right: 0;
      }
      .text {
      }
    }
  }

  .count {
    color: $text-muted;

    a {
      color: $happer-color-2;
    }
  }

  .product-name {
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    a {
      color: #111111;
      &:hover{
        color: $happer-color-2
      }
    }

    @include media-breakpoint-down(500, (500:500px)) {
      margin-bottom: 15px;
    }
  }

  .happer-widget-score {
    font-size: 12px;
  }

  .avg-score {
    font-size: 2rem;
    font-weight: 700;
    margin-right: 20px;

    @include media-breakpoint-down(500, (500:500px)) {
      margin-right: 5px;
    }
  }

  .happer-stars {
    margin-bottom: 5px;
  }

  .btn.btn-icon {
    height: auto;
    width: 30px;
  }

  .happer-widget-product-name {
    flex: 1;
  }

  .happer-widget-product {
    background-color: #f5f5f5;
    padding: 8px 17px;
  }

  .happer-widget-content {
    padding: 16px 20px;
  }
}

//
//.r-flex {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//
//  @include media-breakpoint-down(500, (500:500px)){
//    flex-direction: column;
//  }
//
//}