.happer-widget--style-1 {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  background-color: $white;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
  padding: 13px 18px;
  border-radius: 4px;
  color: #111111;

  .happer-stars {
    .svg-icon.svg-icon-initial svg {
      height: 36px;
      width: 36px;
    }
  }

  .happer-symbol {
    width: 44px;
    height: 44px;
    border-radius: 2px;

    &.symbol .symbol-label {
      border-radius: 2px;
    }
  }

  .happer-widget__product {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 21px;
  }

  .happer-widget__product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 40px;
  }

  .happer-widget__product-link {
    &:hover {
      opacity: 0.8;
    }
  }

  .happer-widget__score {
    font-size: 16px;
    color: #333;
    margin-right: 0;
  }

  .happer-stars {
    margin-top: 17px;
    margin-bottom: 17px;
  }

  .count {
    margin-bottom: 30px;

    a {
      color: #209616;
    }
  }

  .happer-widget__highline {
    font-size: 40px;
    line-height: 1;
  }

  .happer-widget__rating-list {
    list-style: none;
    padding: 0 32px;

    .number {
      margin-right: 4px;
      width: 7px;
    }

    .svg-icon.svg-icon-initial svg {
      height: 10px;
      width: 10px;
      path {
        fill: #111111;
      }
    }

    li {
      display: flex;
      align-items: center;
    }

    .number-star {
      font-size: 14px;
      text-align: left;
      width: 30px;
      display: flex;
      align-items: center;
    }

    .timeline-star {
      background-color: #eee;
      height: 4px;
      position: relative;
      width: calc(100% - 60px);
      border-radius: 8px;
      overflow: hidden;
    }

    .number-percent {
      font-size: 14px;
      line-height: 15px;
      padding-left: 10px;
      text-align: left;
      width: 35px;
    }

    .timing {
      margin-bottom: 0;
      background-color: var(--hp-main-color);
      left: 0;
      height: 4px;
      position: absolute;
      top: 0;
    }
  }
}