.react-select__option {
  position: relative;

  &.react-select__option--is-selected {
    &:before {
      content: "\f00c";
      display: inline-block;
      position: absolute;
      left: 12px;
      font-family: "Font Awesome 5 Free";
      font-weight: bolder;
    }
  }
}