@font-face {
  font-family: GoogleSans;
  src: url('./font/GoogleSans-Regular.woff');
  font-weight: 400;
}

@font-face {
  font-family: GoogleSans;
  src: url('./font/GoogleSans-Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: GoogleSans;
  src: url('./font/GoogleSans-Bold.woff');
  font-weight: 600;
}


@font-face {
  font-family: GoogleSans;
  src: url('./font/GoogleSans-Bold.woff');
  font-weight: 700;
}