.happer-stars__absolute {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
}

.happer-stars__relative {
  position: relative;
  display: inline-block;
}

.happer-stars {
  .svg-icon {
    margin: 0 2px;
  }
}