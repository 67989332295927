$happer-color-2: #209616;
//html, body {
//  overflow: auto;
//}

:root {
  --swiper-navigation-size: 25px !important;
}

.swiper-wrapper {
  align-items: stretch;
}

.happer-widget {
  .swiper-slide {
    height: auto;
  }
}

.text-happer-1 {
  color: var(--hp-main-color);
}

.text-happer-2 {
  color: $happer-color-2;
}

.fs-12px {
  font-size: #{(12/16)}rem;
}

.fs-14px {
  font-size: #{(14/16)}rem;
}

.radius-50 {
  border-radius: 50%;
}

.happer-widget__summary {
  background-color: #fefbf8;
  padding: 10px 20px;
}

.happer-widget__power-by {
  font-size: #{(12/16)}rem;
}

.happer-widget__stars {
}


.happer-widget__score {
  font-size: 2rem;
  font-weight: bold;
  color: var(--hp-main-color);
  margin-right: #{(20/16)}rem;;
}

.happer-widget__gallery {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  > div {
    padding: 5px;
    width: 20%;

    &:last-child {
      margin-right: 0;
    }
  }
}

.happer-widget__slider-item {
  width: 38% !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.happer-widget__slider-item-wrapper {
  background-color: #f4f4f4;
  padding: #{(16/16)}rem;
  border-radius: #{(4/16)}rem;
  height: 100%;
}

.svg-icon.svg-icon-initial svg {
  height: initial;
  width: initial;

}

.svg-icon.svg-change-color {
  path {
    fill: var(--hp-main-color);
  }
}


.svg-icon.svg-happer-2-color {
  path {
    fill: $happer-color-2;
  }
}

.svg-icon.svg-facebook-color {
  path {
    fill: #2374e1;
  }
}

.text-facebook {
  color: #2374e1;
}

.svg-icon.svg-icon-20px svg {
  height: 15px !important;
  width: 20px !important;
}

.happer-symbol.symbol {
  border-radius: 0;

  .symbol-label {
    padding-top: 100%;
    border-radius: 0;
  }
}

#happer-lightbox {
  &.modal-fullscreen .modal-content {
    justify-content: center;
    background-color: rgb(255 255 255 / 71%);
  }
}

.happer-lightbox-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.happer-lightbox-body {
  max-height: 100vh;

  .img-wrapper {
    height: 80vh;
    //padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.happer-symbol-number {
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
}

.happer-widget__content {
  width: 100%;
}

.happer-widget__product-link {
  a {
    color: #2374e1;
    max-height: 4.4rem;
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    &:hover {
      color: var(--hp-main-color);
    }
  }
}

.happer-widget__share {
  .svg-icon {
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.happer-widget__product-content {
  font-size: 0.85rem;
  //overflow: hidden;
  //display: block;
  //-webkit-line-clamp: 2;
  //display: -webkit-box;
  //-webkit-box-orient: vertical;
  //text-overflow: ellipsis;
  //white-space: normal;
  //max-height: 2.5rem;
  //position: relative;
}

//.happer-widget__more {
//  font-size: 0.85rem;
//  cursor: pointer;
//  position: absolute;
//  background: #f4f4f4;
//  top: 20px;
//  right: 0;
//  font-weight: bold;
//}