.w-33px {
  width: 33px;
}

.h-33px {
  height: 33px;
}


.w-60px {
  width: 60px;
}

.h-60px {
  height: 60px;
}

.max-w-480px {
  max-width: 480px;
}