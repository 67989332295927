.template-8 {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  /* frame-shadow */

  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .happer-symbol {
    width: 44px;
    height: 44px;
    border-radius: 2px;
    margin-right: 13px;

    &.symbol .symbol-label {
      border-radius: 2px;
    }
  }

  .buy-now {
    background: #209616;
    color: #fff;
    padding: 10px 20px;
    border-radius: 2px;
    font-size: 14px;
    min-width: 135px;
    display: inline-block;

    .svg-icon {
      margin-right: 9px;
    }

    @include media-breakpoint-down(500, (500:500px)) {
      min-width: initial;
      padding: 5px 10px;
      .svg-icon {
        margin-right: 0;
      }
      .text {
        //display: none;
      }
    }
  }

  .count {
    color: $text-muted;

    a {
      color: $happer-color-2;
    }
  }

  .product-name {
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    a {
      color: #111111;

      &:hover {
        color: $happer-color-2
      }
    }
  }

  .happer-widget-score {
    font-size: 12px;
  }

  .avg-score {
    font-size: 2rem;
    font-weight: 700;
    margin-right: 20px;

    @include media-breakpoint-down(500, (500:500px)) {
      margin-right: 5px;
    }
  }

  .happer-stars {
    margin-bottom: 5px;
  }

  .btn.btn-icon {
    height: auto;
    width: 30px;
  }

  .happer-widget-product-name {
    flex: 1;
  }

  .happer-widget-product {
    background-color: #f5f5f5;
    padding: 12px 28px;
  }

  .happer-widget-content {
    padding: 20px 28px;
  }

  .happer-widget-review {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(450, (450:450px)) {
      justify-content: start;
      align-items: start;
      flex-direction: column;

      .happer-widget-review-content {
        margin-bottom: 10px;
      }
    }
  }

  .happer-widget-review-content {
    flex: 1;
  }

  .power-by {
    .svg-icon.svg-icon-3x svg {
      height: auto !important;
    }
  }

  .user-name {
    color: #111111;
    opacity: 0.7;
  }
}
.fs-18px {
  font-size: #{(18/16)}rem;
}